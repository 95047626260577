import React from "react"
import { Controller, useFormContext } from "react-hook-form"
// import PhoneNumberInput from "react-phone-input-2"
import loadable from "@loadable/component"
import "react-phone-input-2/lib/plain.css"
// import useIP from "@hooks/useIP"
import InputBox from "./InputBox"
import { getUser } from "@starberry/myaccount-website-utils"
import { useGlobalStore } from "@stores/globalStore"

const PhoneNumberInput = loadable(() => import("react-phone-input-2"))

const PhoneInput = ({ name, label, className }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const userData = getUser();
  const {
    countryCode
  } = useGlobalStore()
  

  return (
    <InputBox className={className} name={name} label={label} errors={errors}>
      <Controller
        control={control}
        name={name}
        defaultValue={userData?.mobile?.replace("+", "") || ""}
        value={userData?.mobile?.replace("+", "") || ""}
        render={({ field: { ref, ...field } }) => (
          <PhoneNumberInput
            {...field}
            inputProps={{
              ref,
            }}
            value={field.value}
            country={countryCode }
            enableSearch
            placeholder=""
            inputClass="input-field input-phone"
            dropdownClass="input-phone-dropdown"
            buttonClass="input-field"
            
          />
        )}
      />
    </InputBox>
  )
}

export default PhoneInput
